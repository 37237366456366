import React, { useContext, useEffect, useState } from 'react'
import { CamelCaseWithSpaces, LogInSchoolID, LogInUserID, MMDDYYYY, getAllUsers, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiGetCallAuthKey, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import HDrequestpopup from '../../Components/HDrequestpopup';
import AlertsComp from '../../Components/AlertsComp';
import CustomizationPopup from "../../Components/CustomizationPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faVideo } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'react-js-pagination';
import Imagepopup from '../SupportTicket/Imagepopup';
import VideoPopup from '../SupportTicket/VideoPopup';
import HelpdeskCommentPopup from './HelpdeskCommentPopup';
import { CheckValidation } from '../../Components/Validations';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import Tab from '../../Components/Tab';
import PageHead from '../../Components/PageHead';
import AlertPopup from '../../Components/AlertPopup';

export default function Helpdeskrequest() {
    const { UserData } = useContext(UserContext);
    const [Loading, setLoading] = useState(false);
    const [isShow, setisShow] = useState(false);
    const [GridAllData, setGridAllData] = useState([]);
    const [PopupData, setPopupData] = useState('');
    const [Alerts, setAlerts] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [UserDetails, setUserDetails] = useState([])
    const [AllGroups, setAllGroups] = useState([])
    const [otherTicketsTabs, setOtherTicketsTabs] = useState({
        OpenTicketTabs: "active",
        CloseTicketTabs: "",
        searchForOpen: '',
        searchForClose: ''
    });
    const [columnNames, setColumnNames] = useState([]);
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [PreviewVideo, SetPreviewVideo] = useState('')
    const [videoShow, setVideoShow] = useState(false);
    const [PreviewImg, SetPreviewImg] = useState('');
    const [lgShow, setLgShow] = useState(false);
    const [SortbyKey, setSortbyKey] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [SortbyFlag, setSortbyFlag] = useState('asc');
    const showPagination = GridAllData.length > 0;
    const [allData, setAllData] = useState({
        UpdateFlag: 1,
        Title: "",
        Description: "",
        Id: 0,
        fileName: "",
        OriginalImage: "",
        ShowUploadDiv: "",
        ShowOriginalImage: "d-none",
        Flag: 0,
        TicketId: "",
        TicketNum: '',
        Comment: "",
        AssigneeId: '',
        Documentt: null,
        Filename: "",
        StatusFlag: '',
        SortbyFlag: 'asc'
    });
    const [IsCommentForHelpdesk, setIsCommentForHelpdesk] = useState(false);
    const [helpdeskId, setHelpdeskId] = useState('');
    const [deletePopup, setdeletePopup] = useState(false);
    const [ResolutionStatement, setResolutionStatement] = useState('');
    const [TrackedTime, setTrackedTime] = useState('');
    const [noteVisibility, setNoteVisibility] = useState({});
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const navigate = useNavigate();
    useEffect(() => {
        ApiGetHeaders(43, setColumnNames)
    }, []);
    useEffect(() => {
        if (otherTicketsTabs.OpenTicketTabs == 'active') {
            GetHelpdeskOtherRequests(1, otherTicketsTabs.searchForOpen ? otherTicketsTabs.searchForOpen : null, SortbyKey ? SortbyKey : null);
        } else {
            GetHelpdeskOtherRequests(2, otherTicketsTabs.searchForClose ? otherTicketsTabs.searchForClose : null, SortbyKey ? SortbyKey : null);
        }
    }, [currentPage, LimitForPagination]);
    useEffect(() => {
        getAllUsers(setUserDetails);
        GetUsersandGroups()
    }, [])
    async function GetHelpdeskOtherRequests(flag, searchstring, sortkey) {
        setNoteVisibility({});
        if (searchstring == "" || searchstring == undefined) {
            searchstring = null;
        }
        if (sortkey != null) {
            if (SortbyFlag == "asc") {
                setSortbyFlag('desc')
            } else {
                setSortbyFlag('asc')
            }
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/GetOtherHelpdeskTickets/" + searchstring + '&' + LogInSchoolID + '&' + LogInUserID + '&' + sortkey + '&' + SortbyFlag + '&' + currentPage + '&' + LimitForPagination).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (responseRs.status == "success") {
                        if (flag == 2) {
                            setpageRangeCount(responseRs.CloseTickets.total);
                            setGridAllData(responseRs.CloseTickets.data);
                        } else {
                            setpageRangeCount(responseRs.OpenTickets.total);
                            setGridAllData(responseRs.OpenTickets.data);
                        }
                    } else {
                        setGridAllData([])
                    }
                }
            });
        }, 500);
    }
    async function GetUsersandGroups() {
        await ApiGetCall("/GetGroups/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setAllGroups(responseRs);
                }
            }
        });
    }
    async function UpdateLocation(Assignedid, flag, hdrequestid) {
        setLoading(true);
        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            StaffID: Assignedid,
            Flag: flag, //1 group 2 user
            HdRequestId: hdrequestid
        });
        await ApiPostCall("/assignHelpdeskTicket", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Assigned Successfully"} />);
                    if (otherTicketsTabs.OpenTicketTabs == 'active') {
                        GetHelpdeskOtherRequests(1, otherTicketsTabs.searchForOpen ? otherTicketsTabs.searchForOpen : null, SortbyKey ? SortbyKey : null);
                    } else {
                        GetHelpdeskOtherRequests(2, otherTicketsTabs.searchForClose ? otherTicketsTabs.searchForClose : null, SortbyKey ? SortbyKey : null);
                    }
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        const sortKey = item == "subject" ? "title" : item;
        if (sortKey) {
            const searchQuery = otherTicketsTabs.OpenTicketTabs == 'active'
                ? otherTicketsTabs.searchForOpen
                : otherTicketsTabs.searchForClose;
            const ticketStatus = otherTicketsTabs.OpenTicketTabs == 'active' ? 1 : 2;

            GetHelpdeskOtherRequests(ticketStatus, searchQuery, sortKey);
            setSortbyKey(sortKey);
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "document": item.document != null ? (
                <span>
                    <img
                        src={`${BucketS3Link}${item.document}`}
                        ticketid={item.id}
                        className="cursor-pointer"
                        height='25px'
                        onClick={(e) => {
                            setLgShow(true);
                            SetPreviewImg(item.document);
                        }}
                    />
                </span>
            ) : '-',
            "name": item.name || '-',
            "type": <label style={{ color: "green" }}>{item?.type?.name}</label>,
            "email": item.email || '-',
            "subject": item.title || '-',
            "building": item.building ? item.building.name : '-',
            'description': <div className="note-wrap">{item?.Description || '-'}</div>,
            'video': item.video != null ? (
                <span titleId={item.id}>
                    <FontAwesomeIcon
                        icon={faVideo}
                        style={{ height: '18px' }}
                        title="Video Attachment"
                        className="cursor-pointer"
                        onClick={(e) => {
                            setVideoShow(true);
                            SetPreviewVideo(item.video);
                        }}
                    />
                </span>
            ) : '-',
            'room': item.room_no || '-',
            'requester_type': item.requester_type == 1 ? 'Student / Parent' : 'Teacher',
            'student_no': item.student_no == 'null' || item.student_no == null || item.student_no == '' ? '-' : item.student_no,
            'created_at': MMDDYYYY(item.created_at) || '-'
        };

        return contentMap[columnName] || null;
    }

    async function ChangeStatus() {
        if (allData.StatusFlag == 1) {
            var isFormValid = CheckValidation({ formID: 'resolutionstatementdiv' });
            if (!isFormValid) return;
        }
        setLoading(true)
        var raw = JSON.stringify({
            HdRequestId: helpdeskId,
            Flag: allData.StatusFlag,
            Resolution: ResolutionStatement,
            TrackedTime: TrackedTime
        });
        await ApiPostCall("/changeHelpdeskTicketStatus", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    if (allData.StatusFlag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Ticket Close Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Ticket Open Successfully."} />);
                    }
                    setTimeout(() => {
                        setdeletePopup(false);
                        if (otherTicketsTabs.OpenTicketTabs == 'active') {
                            GetHelpdeskOtherRequests(1, null, null);
                        } else {
                            GetHelpdeskOtherRequests(2, null, null);
                        }
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function GenerateUrl(hdId) {
        var schoolnumber = Cookies.get("Schoolnumber");
        var path = `/helpdesk-ticket/${schoolnumber}/${hdId}`;
        var baseurl = window.location.origin;
        var url = `${baseurl}${path}`;
        const newTab = window.open('', '_blank');
        newTab.location.href = url;
        newTab.focus();
    }
    function handleArrowClick(hid) {
        setNoteVisibility((prevState) => ({
            ...prevState,
            [hid]: !prevState[hid],
        }));
    }
    async function GetHelpdeskDataById(reuqestid) {
        await ApiGetCallAuthKey("/GetHelpdeskById/" + LogInSchoolID + "&" + reuqestid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setPopupData(responseRs);
                setisShow(true);
            }
        });
    }
    function handleTabClick(tabType) {
        if (tabType == "open") {
            GetHelpdeskOtherRequests(1, null, null);
            setOtherTicketsTabs({ ...otherTicketsTabs, OpenTicketTabs: "active", CloseTicketTabs: "", searchForOpen: '' });
            setCurrentPage(1);
            setNoteVisibility({})
        } else if (tabType == "close") {
            GetHelpdeskOtherRequests(2, null, null);
            setOtherTicketsTabs({ ...otherTicketsTabs, OpenTicketTabs: "", CloseTicketTabs: "active", searchForClose: '' });
            setCurrentPage(1);
            setNoteVisibility({})
        }
    }
    function handleSearchChange(e) {
        setCurrentPage(1);
        GetHelpdeskOtherRequests(otherTicketsTabs.OpenTicketTabs == 'active' ? 1 : 2, e.target.value, null);
        otherTicketsTabs.OpenTicketTabs == 'active' ?
            setOtherTicketsTabs({ ...otherTicketsTabs, searchForOpen: e.target.value }) :
            setOtherTicketsTabs({ ...otherTicketsTabs, searchForClose: e.target.value })
    }
    return (
        <>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Other Helpdesk Requests"} handleSearch={handleSearchChange}
                loading={Loading} Alerts={Alerts} showsearch={true} />
            <div className="GridBox p-3 mt-2">
                <div className="row align-items-center mb-3">
                    <div className="col-md-7 px-0">
                        <ul className="nav nav-tabs px-3">
                            <Tab isActive={otherTicketsTabs.OpenTicketTabs == 'active'}
                                label="Open Tickets" onClick={() => handleTabClick('open')} col={'col-md-3'} />
                            <Tab isActive={otherTicketsTabs.CloseTicketTabs == 'active'}
                                label="Closed Ticket" onClick={() => handleTabClick('close')} col={'col-md-3'} />
                        </ul>
                    </div>
                    <div className="col-md-5 text-end px-0">
                        {UserData.MenuAccessFlag == 1 && (
                            <label className="font-14 pt-1 pe-3" style={{ color: 'black', cursor: 'pointer' }} onClick={(e) => navigate("/helpdesk-ticket-assignments")}><u> Helpdesk Assignments</u></label>
                        )}
                    </div>
                </div>
                <div className="greyBox p-3 data-table-container">
                    <table className="table data-table">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    let columnName = item.replace(/_/g, ' ');
                                    return (
                                        item != "video" && item != 'document' && item != 'type' ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                {CamelCaseWithSpaces(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {CamelCaseWithSpaces(columnName)}
                                            </th>
                                    );
                                })}
                                {otherTicketsTabs.OpenTicketTabs == 'active' && (
                                    <th scope="col" className='cursor-pointer fw-600' title="Sort by">Assigned To </th>)}
                                <th scope="col" className='cursor-pointer fw-600 '>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {GridAllData?.length != 0 ?
                                GridAllData?.map((item, i) => {
                                    const isNoteVisible = noteVisibility[item.id];
                                    var returnData = (
                                        <tr key={i} className="OddEvenClass tableBorderHide">
                                            {columnNames?.map(header => (
                                                <td className="py-12" key={header}>  {renderContentForColumn(header, item)} </td>
                                            ))}
                                            {otherTicketsTabs.OpenTicketTabs == 'active' && (
                                                <td className="col-md-3 hoverable-text" onMouseEnter={(e) => { setIsDropdownOpen(i); }} onMouseLeave={() => setIsDropdownOpen(null)}>
                                                    {item.user != null ?
                                                        <>
                                                            {isDropdownOpen === i ? (
                                                                <select className="px-0" defaultValue={item.assigned_to} onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    const selectedOption = e.target.options[e.target.selectedIndex];
                                                                    const flag = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                    UpdateLocation(selectedValue, flag, item.id);
                                                                }}>
                                                                    <option value="0">Select</option>
                                                                    {UserDetails.map((item1, j) => (
                                                                        <option value={item1.id} key={j} data-type="AssigneeId" defaultValue={item1.id == item.assigned_to} >
                                                                            {item1.name}
                                                                        </option>
                                                                    ))}
                                                                    {AllGroups.map((item2, k) => (
                                                                        <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups" >
                                                                            {item2.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <div>{item?.user?.first_name + ' ' + item?.user?.last_name}</div>
                                                            )}

                                                        </>
                                                        : item.usergroup != null ?
                                                            <>
                                                                {isDropdownOpen === i ? (
                                                                    <select className="px-0" defaultValue={item.assigned_to} onChange={(e) => {
                                                                        const selectedValue = e.target.value;
                                                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                                                        const flag = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                        UpdateLocation(selectedValue, flag, item.id);
                                                                    }}>
                                                                        <option value="0">Select</option>
                                                                        {UserDetails.map((item1, j) => (
                                                                            <option value={item1.id} key={j} data-type="AssigneeId">
                                                                                {item1.name}
                                                                            </option>
                                                                        ))}
                                                                        {AllGroups.map((item2, k) => (
                                                                            <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups" defaultValue={item2.id == item.assigned_to}>
                                                                                {item2.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                ) : (
                                                                    <div>{item?.usergroup?.name}</div>
                                                                )}

                                                            </>
                                                            :
                                                            <>
                                                                {isDropdownOpen === i ? (
                                                                    <select className="px-0" onChange={(e) => {
                                                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                                                        const source = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                        UpdateLocation(e.target.value, source, item.id);
                                                                    }}>
                                                                        <option value="0">Select</option>
                                                                        {UserDetails.map((item1, j) => (
                                                                            <option value={item1.id} key={j} data-type="AssigneeId">
                                                                                {item1.name}
                                                                            </option>
                                                                        ))}
                                                                        {AllGroups.map((item2, k) => (
                                                                            <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups">
                                                                                {item2.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                ) : (
                                                                    <div className="py-2">-</div>
                                                                )}
                                                            </>
                                                    }
                                                </td>
                                            )}
                                            <td className="py-12 d-flex">
                                                {otherTicketsTabs.OpenTicketTabs == 'active' && (
                                                    <FontAwesomeIcon icon={faComment} style={{ color: "black", height: "18px" }} className='cursor-pointer px-1' onClick={(e) => { setHelpdeskId(item.id); setIsCommentForHelpdesk(true); }} title="Comment" />
                                                )}
                                                {otherTicketsTabs.OpenTicketTabs == 'active' ?
                                                    <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as closed" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, StatusFlag: 1 }); setHelpdeskId(item.id) }} height='18px' />
                                                    :
                                                    <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as Open" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, StatusFlag: 2 }); setHelpdeskId(item.id) }} height='18px' />
                                                }
                                                <img src="/images/previewpdficon.svg" className="cursor-pointer px-1" title='Preview Helpdesk Tickets' titleid={item.id} onClick={(e) => GenerateUrl(item.id)} height='18px' />
                                                {item.status == 2 && (
                                                    <>
                                                        <img src="/images/DownRoundArrow.svg" title="Show Resolution Statement" className={`cursor-pointer px-1 img-fluid ${isNoteVisible ? "d-none" : ""}`} onClick={() => handleArrowClick(item.id)} height='18px' />
                                                        <img src="/images/UpRoundArrow.svg" title="Hide Resolution Statement" className={`img-fluid px-1 cursor-pointer ${isNoteVisible ? "" : "d-none"}`} onClick={() => handleArrowClick(item.id)} height='18px' />
                                                    </>
                                                )}
                                                <img src='/images/InfoIcon.svg' className='img-fluid ps-2 cursor-pointer' title='Show Helpdesk Request' style={{ height: "16px" }} onClick={(e) => GetHelpdeskDataById(item.id)} />
                                            </td>
                                        </tr>
                                    );
                                    if (isNoteVisible) {
                                        returnData = (
                                            <>
                                                {returnData}
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Resolution Statement:</b>
                                                        <div style={{ display: "inline" }}> {item.resolution || '-'} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Open Date:</b>
                                                        <div style={{ display: "inline" }}> {item.created_at ? MMDDYYYY(item.created_at) : '-'} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Completion Time:</b>
                                                        <div style={{ display: "inline" }}> {item.completion_time ? item.completion_time + ` hours` : '-'} </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    return returnData;
                                })
                                :
                                <tr className="text-center">
                                    <td colSpan={16}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {showPagination && (
                        <Pagination
                            activePage={currentPage}
                            totalItemsCount={pageRangeCount}
                            pageRangeDisplayed={15}
                            itemsCountPerPage={LimitForPagination}
                            onChange={(e) => setCurrentPage(e)}
                            color="primary"
                            lastPageText=""
                            firstPageText=""
                            itemClassFirst="MasterClass"
                            itemClassPrev="MasterClass"
                            itemClassNext="MasterClass"
                            itemClassLast="MasterClass"
                        />
                    )}
                </div>
            </div>
            <HDrequestpopup isShow={isShow} closepopup={(e) => setisShow(false)} PopupData={PopupData} />
            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Other Requests'} GetCall={() => ApiGetHeaders(43, setColumnNames)} />
            )}
            {lgShow == true && (
                <Imagepopup lgShow={lgShow} setLgShow={setLgShow} PreviewImg={PreviewImg} />
            )}
            {videoShow == true && (
                <VideoPopup videoShow={videoShow} setVideoShow={setVideoShow} PreviewVideo={PreviewVideo} />
            )}

            {IsCommentForHelpdesk == true && (
                <HelpdeskCommentPopup IsComment={IsCommentForHelpdesk} setIsComment={(e) => setIsCommentForHelpdesk(false)}
                    setAllData={setAllData} allData={allData} setLoading={setLoading}
                    GetAllSupportTickets={(e) => GetHelpdeskOtherRequests(1, null, null)} setAlerts={setAlerts} helpdeskId={helpdeskId} />
            )}
            <AlertPopup isshow={deletePopup} img={allData.StatusFlag == 1 ? '/images/redInfo.svg' : '/images/greenInfo.svg'}
                headingtext={allData.StatusFlag == 1 ? 'Are you sure want to close ticket?' : 'Are you sure want to Open ticket?'}
                text={allData.StatusFlag == 1 && (
                    <div className="col-12 parent pt-2 px-4" id="resolutionstatementdiv">
                        <div className="col-12">
                            <textarea type="textarea" rows={2} autoComplete="off" required name="resolution" className="form-control" onChange={(e) => setResolutionStatement(e.target.value)} />
                            <div className='FormLabel'>What did you do to resolve the issue?<label className='redText'>*</label></div>
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                        <div className="col-12 pt-4">
                            <input type="number" className="form-control" autoComplete='off'
                                name="timetrack" min={0} onChange={(e) => setTrackedTime(e.target.value)} required />
                            <div className='FormLabel'>How many hours did you spend to resolve this ticket?<label className='redText'>*</label></div>
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                )}
                cancelcall={(e) => { setResolutionStatement(""); setdeletePopup(false); setAllData({ ...allData, TicketId: "" }) }}
                canceltext={"Cancel"} successtext={"Yes"} successcall={(e) => ChangeStatus(e)} btntype={allData.StatusFlag == 1 ? 'deleteBtn' : 'SaveBtn'} />
        </>
    )
}
