import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey, ApiPostCallAuthKey } from '../../JS/Connector';
import Cookies from "js-cookie";
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import { LogInSchoolID } from '../../JS/Common';
import Popup from '../../Components/Popup';

export default function HelpdeskCommentPopup(props) {
    const [CommentData, setCommentData] = useState('');
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    var setAllData = props.setAllData;
    var allData = props.allData;
    useEffect(() => {
        ApiGetCallAuthKey("/GetHelpdeskById/" + LogInSchoolID + "&" + props.helpdeskId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setCommentData(responseRs);
            }
        });
    }, [])
    function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const base64String = event.target.result;
            const base64Value = base64String.split(";base64,").pop();
            setAllData({ ...allData, fileName: file.name, Flag: 1, Documentt: base64Value });
        };
        reader.readAsDataURL(file);
    };
    async function SaveComment() {
        var isFormValid = CheckValidation({ formID: 'CommentDiv' });
        if (!isFormValid) return;
        props.setLoading(true);
        var raw = JSON.stringify({
            SchoolID: Cookies.get('schoolid'),
            HdRequestId: props.helpdeskId,
            Comment: allData.Comment,
            AssignStaffMember: Cookies.get('CsvUserId'),
            Flag: 1,
            Document: allData.Documentt

        });
        await ApiPostCallAuthKey("/addCommentsonHelpdeskTicket", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                props.setLoading(false);
                if (result == "success") {
                    props.setAlerts(<AlertsComp show={true} variant="success" msg={"Comment Successfully."} />);
                    setTimeout(() => {
                        props.setIsComment();
                        props.GetAllSupportTickets(1, null, null);
                        props.setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    props.setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const modalbody = (
        <>
            <div className="row px-2">
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600">Subject : </div>
                    <div className="col-md-6">{CommentData.title || '-'}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pt-0 pe-0 row">
                    <div className="col-md-6 fw-600"> Name : </div>
                    <div className="col-md-6"> {CommentData.name || '-'}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600">Email : </div>
                    <div className="col-md-6" style={{ wordBreak: 'break-all' }}>{CommentData.email || '-'}</div>
                </div>
                {CommentData.requester_type == 2 && (
                    <div className="col-xl-6 col-12 p-1 pe-0 row">
                        <div className="col-md-6 fw-600"> Room No. : </div>
                        <div className="col-md-6">{CommentData.room_name ? CommentData.room_name : CommentData.room?.name}</div>
                    </div>
                )}
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600"> Building : </div>
                    <div className="col-md-6">{CommentData.building_name ? CommentData.building_name : CommentData.BuildingName}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600">Student No.: </div>
                    <div className="col-md-6">{CommentData.student_no || '-'}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600"> Role : </div>
                    <div className="col-md-6">{CommentData.requester_type == 1 ? 'Student / Parent' : 'Teacher'}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pe-0 row">
                    <div className="col-md-6 fw-600">Attached Image : </div>
                    <div className="col-md-6">{CommentData.document != null ? <img src={`${BucketS3Link}${CommentData.document}`} height="25px" /> : '-'}</div>
                </div>
                <div className="col-xl-6 col-12 p-1 pe-0 row align-items-center">
                    <div className="col-md-6 fw-600">Attached Video : </div>
                    <div className="col-md-6">{CommentData.video != null ?
                        <video width="30" height="30">
                            <source src={`${BucketS3Link}${CommentData.video}`} type="video/mp4" />
                        </video>
                        : <>-</>}
                    </div>
                </div>
                <div className="col-xl-12 col-12 p-1 pb-3 pe-0 row">
                    <div className="col-md-3 fw-600">Ticket Description :  </div>
                    <div className="col-md-9">{CommentData.description || '-'}</div>
                </div>
            </div>
            <div className='row'>
                <hr />
                <div className='col-md-6 pb-2'>
                    <label className='fw-600'>Helpdesk Type : </label>&nbsp;{CommentData?.additional_info?.typeName}
                </div>
                <div className='col-md-6 pb-2'>
                    <label className='fw-600'>Category : </label>&nbsp;{CommentData?.additional_info?.categoryName || '-'}
                </div>
                <div className='col-md-6 pb-2'>
                    <label className='fw-600'>Subcategory : </label>&nbsp;{CommentData?.additional_info?.subcategoryName || '-'}
                </div>
                <div className='col-md-6 pb-2'>
                    <label className='fw-600'>Issue : </label>&nbsp;{CommentData?.additional_info?.issueName || '-'}
                </div>
                <hr />
            </div>
            <div id="CommentDiv" className="parent row">
                <div className='col-md-6 pt-3'>
                    <textarea rows={4} required autoComplete='off' name='comment' className="form-control"
                        value={allData.Comment} onChange={(e) => setAllData({ ...allData, Comment: e.target.value })} />
                    <div className='FormLabel'>Comment<label className='redText'>*</label></div>
                    <span className="form-text invalid-feedback">
                        *required
                    </span>
                </div>
                <div className='col-md-6 pt-3'>
                    <input type="file" label="img" id="UploadFileId" name="myFile" accept=".jpg,.png,.svg,.jpeg" onChange={(e) => handlePdfFileUpload(e)} />
                    <label className="ImportInventoryBtn col-md-8" htmlFor="UploadFileId">
                        Choose File
                    </label>
                    <input type="file" className=" col-md-4 ImportInventoryBtn" />
                    <label className="ImportInventoryBtn1 text-center col-md-4" htmlFor="UploadFileId" style={{ backgroundColor: "#3CBBA5", color: "white", height: "40px", }}>
                        Browse
                    </label>
                    <div className='col-12 FormLabel'>Attach Image or Screenshot</div>
                    {allData.fileName && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{allData.fileName} </label>}
                </div>
            </div>
        </>
    )
    return (
        <Popup isshow={props.IsComment} size={"lg"} title={"Helpdesk Ticket Comments"}
            closePopup={(e) => { props.setIsComment(); setAllData({ ...allData, Filename: '', Description: '', Comment: '', Documentt: '' }); }}
            modalBody={modalbody} handleSave={SaveComment} visibleSaveBtn={true} btnText={"Comment"} />
    )
}
