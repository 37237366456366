import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import AlertsComp from '../../../Components/AlertsComp';
import { MMDDYYYY } from '../../../JS/Common';

export default function OpenHelpdeskTicketsbyBuilding(props) {
    var vData = props?.AllReportData?.data;
    const [series, setSeries] = useState([]);
    const [chartLoaded, setChartLoaded] = useState(false);
    useEffect(() => {
        const newSeries = vData?.series?.map(value => parseFloat(value)?.toFixed(2)) || [];
        setSeries(newSeries);
        setChartLoaded(true);
    }, [vData]);
    const options1 = {
        chart: {
            height: 350,
            type: "bar",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // Place labels on top of the bars
                },
            },
        },
        xaxis: {
            categories: vData?.categories,
            title: {
                text: "Building",
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return parseFloat(val)?.toFixed(2);
                },
            },
            title: {
                text: "No of Ticket",
            },
        },
        dataLabels: {
            style: {
                colors: ["black"],
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ["#92b5e4"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "Total Tickets: " + val;
                },
                title: {
                    formatter: function (seriesName, val) {
                        return "";
                    },
                },
            },
        },
    };
    const downloadCsv = () => {
        const csvContent = [];
        const gradeMap = new Map();
        vData?.pdfData?.forEach((buildingTickets) => {
            if (buildingTickets.length > 0) {
                const building = buildingTickets[0]?.building?.name || 'Unknown'; // Get the building name
                if (!gradeMap.has(building)) {
                    gradeMap.set(building, []);
                }
                buildingTickets.forEach((ticket) => {
                    const rowData = {
                        'Subject': ticket.title,
                        'Name': ticket?.name,
                        'Email': ticket?.email,
                        'Room': ticket?.room_no,
                        'Student No.': ticket?.student_no, // Handle null/undefined values
                        'Created at': MMDDYYYY(ticket.created_at),  // Format the date
                    };

                    // Push the row data to the map for the current building
                    gradeMap.get(building).push(rowData);
                });
            }
        });


        gradeMap.forEach((gradeData, building) => {
            csvContent.push([`Building: ${building}`]);
            const headers = ['Subject', 'Name', 'Email', 'Room', 'Student No.', 'Created at'];
            csvContent.push(headers);
            gradeData.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
        });

        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n'); // Convert to CSV string
            const blob = new Blob([csvString], { type: 'text/csv' });  // Create a Blob with the CSV content
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'OpenHelpdekTickets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            props.setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                props.setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };
    return (
        <div className="text-center h-100 greyBox">
            <div className="pb-5 w-100">
                <div className='row'>
                    <h4>Open Helpdesk Tickets By Building</h4>
                </div>
                <div className='justify-content-center d-flex mb-3'>
                    <label className='cursor-pointer d-flex align-items-center' onClick={downloadCsv} style={{ fontSize: "12px" }}>
                        <img src="/images/downloadPlan.svg" className="pe-1" title='Download CSV' height='10px' />
                        <label className='cursor-pointer'>Download Detailed Reports</label>
                    </label>
                </div>
                {props?.AllReportData?.loder == true ?
                    <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                    :
                    <div id="chart">
                        {chartLoaded && (
                            <ReactApexChart options={options1} series={[{ data: series }]} type="bar" height={350} />
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
