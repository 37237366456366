import React, { useState, useEffect, useRef } from "react";
import { HideLoder, ShowLoder, getAllBuildings, isValidEmail } from "../../JS/Common";
import { CheckValidation } from "../../Components/Validations";
import { ApiGetCallAuthKey } from "../../JS/Connector";
import AlertsComp from "../../Components/AlertsComp";
import ReCAPTCHA from "react-google-recaptcha";
import VideoUpload from "./VideoUpload";
import { GoogleLoginForSupportTicket } from "../Signin/GoogleLoginForSupportTicket";
import { MicrosoftLoginForSupportTicket } from "../Signin/MicrosoftLoginForSupportTicket";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
export function TicketForm() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const [loading, setLoading] = useState(false);
    const [schoolname, setschoolname] = useState("");
    const [Alerts, setAlerts] = useState("");
    const [schoolid, setschoolid] = useState("");
    const [EmailValidation, setEmailValidation] = useState("");
    const [TechMainData, setTechMainData] = useState([]);
    const [Buildings, setBuildings] = useState([]);
    const [TechMaintenceSelected, setTechMaintenceSelected] = useState('');
    const [TachDetails, setTachDetails] = useState({
        typeflag: 1,
        Showoption: '',
        OtherFields: 'd-none'
    })
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    const [allData, setallData] = useState({
        title: "",
        name: "",
        email: "",
        building: "",
        roomno: "",
        Document: null,
        Filename: "",
        Description: "",
        Flag: 2,
        StudentNumber: '',
        Buildingname: '',
        loginAccesstoken: '',
        roomname: ''
    })
    const [CaptchaKey, setCaptchaKey] = useState("");
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [isCheckedForOther, setIsCheckedForOther] = useState(false);
    const [Video, setVideo] = useState('');
    const [LoginFlag, setLoginFlag] = useState(2);
    const [GoogleMicrosoftFlag, setGoogleMicrosoftFlag] = useState('');
    const [RoomData, setRoomData] = useState([]);
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const rawCookie = Cookies.get('supportTicketDetails');
    const recaptcharef = useRef();
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", process.env.REACT_APP_AuthKey);
    useEffect(() => {
        ApiGetCallAuthKey("/schoolDatabyNumber/" + schoolnumber).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setschoolname(responseRs.msg.name);
                setschoolid(responseRs.msg.id);
                setLoginFlag(responseRs.msg.sso);
                setGoogleMicrosoftFlag(responseRs.ssoType);
                if (responseRs?.msg?.helpdesk_updated_at != null) {
                    window.location.href = `/helpdesk-request/${schoolnumber}`
                }
                if (responseRs.msg.sso != 1) {
                    setallData({ ...allData, name: '', email: '', loginAccesstoken: '' })
                }
                getAllBuildings(setBuildings, responseRs.msg.id);
            }
        });
        TechMaintenceData(1);
        setTachDetails({ ...TachDetails, typeflag: 1 })
    }, []);

    function GetCookiesData() {
        if (rawCookie != undefined) {
            const decodedString = decodeURIComponent(rawCookie);
            const userDetailsForSupportTicket = JSON.parse(decodedString);
            setallData({ ...allData, name: userDetailsForSupportTicket.name, email: userDetailsForSupportTicket.email, loginAccesstoken: userDetailsForSupportTicket.accessToken })
        }
    }

    function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            setallData({ ...allData, Document: file, Filename: file.name });
        };
        reader.readAsDataURL(file);
    };

    async function SaveSupportTicket() {


        var isFormValid = CheckValidation({ formID: 'SupportTicketForm' });
        var emailvalid = isValidEmail(allData.email);
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (emailvalid == false) {
            setEmailValidation("Invalid Email")
            isFormValid = false;
        }
        if (!isFormValid) return;
        setLoading(true);

        const formdata = new FormData();
        formdata.append('SchoolID', schoolid);
        formdata.append('Title', allData.title);
        formdata.append('Name', allData.name);
        formdata.append('Email', allData.email);
        formdata.append('RoomNo', allData.roomno == "other" ? 0 : allData.roomno);
        formdata.append('RoomName', allData.roomname);
        formdata.append('Document', allData.Document);
        formdata.append('Description', allData.Description);
        formdata.append('StudentNumber', allData.StudentNumber);
        formdata.append('Flag', allData.Flag);
        formdata.append('Captcha', CaptchaKey);
        formdata.append('TypeFlag', TachDetails.typeflag);
        formdata.append('TypeChildId', TechMaintenceSelected);
        formdata.append('Building', allData.building == "" ? 0 : allData.building);
        formdata.append('buildingname', allData.Buildingname);
        formdata.append('videos', Video);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/addSupportTicketFromLink`, requestOptions).then(response => response.text())
            .then(result => {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Ticket Created Successfully."} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        window.location.href = `/thankyou/${schoolnumber}/${responseRs.SupportTicketDetails.support_ticket_num}/${responseRs.SupportTicketDetails.ticket_gu_id}`
                    }, 2500);
                } else {
                    recaptcharef.current.reset();
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    async function TechMaintenceData(flag) {
        await ApiGetCallAuthKey("/getTechnologyAndMaintenanceData/" + flag).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (flag == 1) {
                    setTachDetails({ ...TachDetails, Showoption: "", typeflag: 1, OtherFields: "d-none" });
                    setTechMainData(responseRs.Technologies);
                } else {
                    setTachDetails({ ...TachDetails, Showoption: "", typeflag: 2, OtherFields: "d-none" });
                    setTechMainData(responseRs.Maintenace);
                }
                setTechMaintenceSelected('');
            }
        });
    }
    function getRooms(buildingid) {
        ApiGetCallAuthKey("/getBuildingDataById/" + buildingid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setRoomData(responseRs.msg?.room)
            }
        });
    }
    return (
        <React.Fragment>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row">
                {LoginFlag == 0 ?
                    <div className="col-md-10 offset-md-1">
                        <div className="m-4 px-5">
                            <div className="GridBox m-auto mx-5">
                                <div className="col-12 py-4 text-center">
                                    <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                                </div>
                                <div className='Header text-center'>
                                    <h5 className="mb-0">Create Support Ticket for <span className="schoolColorStatus">{schoolname}</span></h5>
                                    <img src='/images/supportTicketLine.svg' className='img-fluid ' />
                                </div>
                                <div className="row">
                                    <div className="col-md-6 font-15 text-center row py-3">
                                        <div className="col-12">
                                            <b className="font-15" style={{ color: "#495057" }}>
                                                What is your role in the educational context?
                                            </b>
                                        </div>
                                        <div className="col-lg-10 offset-lg-1 text-center py-3">
                                            <div className='btns'>
                                                <label>
                                                    <input defaultChecked name='role' type='radio' onClick={(e) => {
                                                        setallData({
                                                            ...allData, Flag: 2, title: "", building: "", roomno: "",
                                                            Document: null, Filename: "", Description: "", StudentNumber: '',
                                                        })
                                                        setEmailValidation("");
                                                    }} />
                                                    <span className='btnnew firstnew'>Teacher</span>
                                                </label>
                                                <label>
                                                    <input name='role' type='radio' onClick={(e) => {
                                                        setallData({
                                                            ...allData, Flag: 1, title: "", building: "", roomno: "",
                                                            Document: null, Filename: "", Description: "", StudentNumber: ''
                                                        })
                                                        setEmailValidation("");
                                                    }} />
                                                    <span className='btnnew lastnew'>Student / Parent</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 font-15 text-center row py-3">
                                        <div className="col-12">
                                            <b className="font-16" style={{ color: "#495057" }}>Choose based on the specific issue you are facing.</b>
                                        </div>
                                        <div className="col-lg-10 offset-lg-1 text-center py-3">
                                            <div className='btns'>
                                                <label>
                                                    <input defaultChecked name='Issuefacing' type='radio' onChange={(e) => { TechMaintenceData(1); }} />
                                                    <span className='btnnew firstnew'>Technology</span>
                                                </label>
                                                <label>
                                                    <input name='Issuefacing' type='radio' onChange={(e) => { TechMaintenceData(2); }} />
                                                    <span className='btnnew lastnew'>Maintenance</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div id='SupportTicketForm' className="parent">
                                    <div className='row'>
                                        <div className={`${TachDetails.Showoption}`}>
                                            <div className="col-12 px-4">
                                                <div className="col-12  py-2">
                                                    {TachDetails.typeflag == 1 ?
                                                        <b className="font-15" style={{ color: "#495057" }} >Choose Technology Options.</b>
                                                        :
                                                        <b className="font-15" style={{ color: "#495057" }}>Choose Maintenance Options.</b>
                                                    }
                                                </div>
                                                <div className="row px-4">
                                                    {TechMainData.map((item, i) => (
                                                        <div className="col-md-4 px-0 d-flex align-items-center list__item" key={item.id}>
                                                            <input type="radio" className="radio-btn" name="technology" itemid={item.id}
                                                                checked={TechMaintenceSelected === item.id}
                                                                id={`opt-${item.id}`} onChange={(e) => {
                                                                    setTechMaintenceSelected(item.id);
                                                                    setTachDetails({ ...TachDetails, OtherFields: "", Showoption: "" });
                                                                    GetCookiesData();
                                                                }} />
                                                            <label htmlFor={`opt-${item.id}`} className="label" style={{ color: '#4C5258' }}>
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="align-items-center row py-2 px-4">
                                                <b className="font-15" style={{ color: "#495057" }}>
                                                    Do you know issue is related to which Building?</b>
                                            </div>
                                            <div>
                                                <div className='col-12 py-2 px-4'>
                                                    <div className="row">
                                                        {Buildings.map((item, i) => {
                                                            return <div className="col-md-4 py-2">
                                                                <input type="radio" className="form-check-input me-2"
                                                                    onChange={(e) => {
                                                                        setallData({ ...allData, building: item.id });
                                                                        setIsCheckedForOther(false); getRooms(item.id)
                                                                    }} name="supportticketbuilding"
                                                                />
                                                                {item.name}
                                                            </div>
                                                        })}
                                                        <div className="col-md-4 py-2">
                                                            <input type="radio" className="form-check-input me-2" name="supportticketbuilding"
                                                                checked={isCheckedForOther} onChange={(e) => { setIsCheckedForOther(e.target.checked) }}
                                                            />
                                                            Other
                                                        </div>
                                                        {isCheckedForOther == true && (
                                                            <div className='col-4'>
                                                                <input type="text" placeholder="Enter Building*" required autoComplete='off' name='building' className="form-control" value={allData.Buildingname} onChange={(e) => setallData({ ...allData, Buildingname: e.target.value })} />
                                                                <span className="form-text invalid-feedback">
                                                                    *required
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row px-4 ${TachDetails.OtherFields}`}>
                                        <div className='col-md-4 py-3'>
                                            <div className='col-12'>
                                                <input type="text" placeholder="Name*" autoComplete='off' name='name' className="form-control" required value={allData.name} onChange={(e) => setallData({ ...allData, name: e.target.value })} />
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-md-4 py-3'>
                                            <div className='col-12'>
                                                <input type="email" placeholder="Email*" autoComplete='off' name='email' className="form-control" value={allData.email} onChange={(e) => setallData({ ...allData, email: e.target.value })} />
                                                <span style={{ fontSize: '.875em', color: '#dc3545' }}>{EmailValidation}</span>
                                            </div>
                                        </div>
                                        {allData.Flag == 2 && (
                                            <>
                                                <div className='col-md-4 py-3'>
                                                    <div className='col-12'>
                                                        <select value={allData.roomno} required onChange={(e) => {
                                                            setallData({ ...allData, roomno: e.target.value })
                                                        }}>
                                                            <option value="0">Select Room</option>
                                                            {RoomData?.length &&
                                                                RoomData?.map((item, i) => {
                                                                    return <option key={i} value={item.id}>{item.name}</option>
                                                                })
                                                            }
                                                            <option value="other">Other</option>

                                                        </select>
                                                        <span className="form-text invalid-feedback">
                                                            *required
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {allData.roomno == "other" && (
                                            <div className='col-4 py-3'>
                                                <input type="text" placeholder="Enter Room*" required autoComplete='off' name='roomname' className="form-control"
                                                    value={allData.roomname} onChange={(e) => setallData({ ...allData, roomname: e.target.value })} />
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                        )}
                                        <div className='col-md-4 py-3'>
                                            <div className='col-12'>
                                                <input type="text" placeholder="Student No." autoComplete='off' name='roomno' className="form-control" value={allData.StudentNumber} onChange={(e) => setallData({ ...allData, StudentNumber: e.target.value.toUpperCase() })} />
                                            </div>
                                        </div>
                                        <div className='col-md-4 py-3'>
                                            <div className='col-12'>
                                                <input type="textarea" placeholder="Ticket Title *" autoComplete='off' name='title' className="form-control" required value={allData.title} onChange={(e) => setallData({ ...allData, title: e.target.value })} />
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-md-4 py-3'>
                                            <input type="file" label="img" id="UploadFileId" name="myFile" accept=".jpg,.png,.svg,.jpeg" onChange={(e) => handlePdfFileUpload(e)} />
                                            <label className="supportTicketbtn col-md-12" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                                                Choose File (Attach Image)
                                            </label>
                                            {allData.Filename && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{allData.Filename} </label>}
                                        </div>
                                        <VideoUpload selectedFile={(e) => setVideo(e)} />
                                        <div className="col-md-8 pb-3">
                                            <textarea placeholder="Ticket Description" type="textarea" rows={3} autoComplete="off" name="RepairedNm" className="form-control" value={allData.Description} onChange={(e) => setallData({ ...allData, Description: e.target.value })} />
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <ReCAPTCHA ref={recaptcharef} sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                                        </div>
                                        <div className="text-center">
                                            <span style={{ fontSize: '.875em', color: '#dc3545' }}>{blankCaptcha}</span>
                                        </div>
                                        <div className="col-md-12 text-center mt-2 py-3 justify-content-center align-items-center">
                                            <button className="SaveBtn mt-2" onClick={(e) => SaveSupportTicket(e)}>Submit Ticket</button>
                                            <label className="ms-2 cursor-pointer" onClick={(e) => window.location.reload()}> <u>Reset</u></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    LoginFlag == 1 ?
                        GoogleMicrosoftFlag == 1 ?
                            <>
                                <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
                                <div className="position-relative MainDiv">
                                    <GoogleLoginForSupportTicket flag="supportticket" setLoginFlag={setLoginFlag} setallData={setallData} allData={allData} />
                                </div>
                            </>
                            :
                            <>
                                <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
                                <div className="position-relative MainDiv">
                                    <MicrosoftLoginForSupportTicket flag="supportticket" setLoginFlag={setLoginFlag} />
                                </div>
                            </>
                        :
                        <></>
                }
            </div >
        </React.Fragment>
    )
}
